<template>
  <div id="pagination" v-if="pageCount > 0">
    <ul class="epages">
      <li class="prev" @click="prePage">
        上一页
      </li>
      <li :class="{ active: currentPage == 0 }" @click="JumpTo(0)">
        1
      </li>
      <li v-show="currentPage > 5 && pageCount > 10">...</li>
      <li
        :class="{ active: currentPage == index + offset - 1 }"
        v-for="(item, index) in middlePages"
        :key="index"
        @click="JumpTo(index + offset - 1)"
      >
        {{ index + offset }}
      </li>
      <li v-show="currentPage < bigLimit && pageCount > 10">
        ...
      </li>
      <li
        :class="{ active: currentPage == pageCount - 1 }"
        @click="JumpTo(pageCount - 1)"
        v-if="pageCount > 1"
      >
        {{ pageCount }}
      </li>
      <li class="next" @click="nextPage">
        下一页
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ["pageCount", "currentPage"],
  data() {
    return {};
  },
  methods: {
    JumpTo(index) {
      this.$emit("switchPage", index);
    },
    prePage() {
      this.$emit("prePage");
    },
    nextPage() {
      this.$emit("nextPage");
    }
  },
  computed: {
    middlePages() {
      if (this.pageCount <= 2) {
        return 0;
      } else if (this.pageCount > 2 && this.pageCount <= 10) {
        return this.pageCount - 2;
      } else {
        return this.currentPage > 999 ? 5 : 8;
      }
    },
    bigLimit() {
      return this.middlePages > 5 ? this.pageCount - 6 : this.pageCount - 3;
    },
    offset() {
      if (this.currentPage <= 5) {
        return 2;
      } else if (this.currentPage >= this.bigLimit) {
        return this.bigLimit - 2;
      } else {
        return this.middlePages > 5
          ? this.currentPage - 3
          : this.currentPage - 2;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
#pagination {
  display: flex;
  align-items: center;
  width: 860px;
  height: 54px;
  text-align: center;
  line-height: 54px;
  margin: 60px auto;
  // background-color: #f5f5f5;
  ul {
    // width: 860px;
    list-style: none;
    padding: 0;
    margin: 0 auto;
    height: 54px;
    display: flex;
    background-color: #f5f5f5;
  }
  li {
    cursor: pointer;
    list-style: none;
    width: 54px;
    height: 54px;
  }
  li.prev,
  li.next {
    width: 120px;
    height: 54px;
  }
  li:hover {
    background-color: #004898;
    color: #fff;
  }
  li.active {
    background-color: #004898;
    color: #fff;
  }
}
</style>
