<template>
  <div id="information-list-wrapper">
    <div id="information-list">
      <ul class="information-list">
        <li v-for="(item, index) in newList[activeIndex]" :key="index">
          <router-link :to="{ name: 'Details', params: { id: item.id } }">
          </router-link>
          <div class="left">
            <div class="information-date">{{ item.date }}</div>
            <div class="information-title">{{ item.title }}</div>
            <div class="information-abstract" v-if="item.abstract">
              {{ item.abstract }}
            </div>
          </div>
          <div class="right" v-if="item.img">
            <img :src="item.img" alt="" />
          </div>
        </li>
      </ul>
    </div>

    <!-- 分页 -->
    <Pagination
      :pageCount="newList.length"
      :currentPage="activeIndex"
      @prePage="handlePrePage"
      @nextPage="handleNextPage"
      @switchPage="handleSwitchPage($event)"
    />
  </div>
</template>
<script>
import Pagination from "@/components/Pagination.vue";

export default {
  name: "Story",
  components: {
    Pagination
  },
  data() {
    return {
      informationList: [
        {
          id: 1,
          date: "2020-05-26",
          title: "1留守老人如何养老？",
          abstract:
            "这些企业家都有一个共同的身份，就是长江商学院EMBA26期5班的学子。施甸距离上海，有近三千公里。上海更是受新增疫情影响，出行有诸多不便。大家在远赴施甸之前，做了核酸检测，确保万无一失。",
          img: require("@/assets/images/home-new5.jpg")
        },
        {
          id: 2,
          date: "2020-05-26",
          title: "2留守老人如何养老？",
          abstract:
            "这些企业家都有一个共同的身份，就是长江商学院EMBA26期5班的学子。施甸距离上海，有近三千公里。上海更是受新增疫情影响，出行有诸多不便。大家在远赴施甸之前，做了核酸检测，确保万无一失。",
          img: require("@/assets/images/home-new5.jpg")
        },
        {
          id: 3,
          date: "2020-05-26",
          title: "3留守老人如何养老？",
          abstract:
            "这些企业家都有一个共同的身份，就是长江商学院EMBA26期5班的学子。施甸距离上海，有近三千公里。上海更是受新增疫情影响，出行有诸多不便。大家在远赴施甸之前，做了核酸检测，确保万无一失。",
          img: ""
        },
        {
          id: 4,
          date: "2020-05-26",
          title: "4留守老人如何养老？",
          abstract: "",
          img: ""
        },
        {
          id: 5,
          date: "2020-05-26",
          title: "5留守老人如何养老？",
          abstract:
            "这些企业家都有一个共同的身份，就是长江商学院EMBA26期5班的学子。施甸距离上海，有近三千公里。上海更是受新增疫情影响，出行有诸多不便。大家在远赴施甸之前，做了核酸检测，确保万无一失。",
          img: require("@/assets/images/home-new5.jpg")
        }
      ],
      currentIndex: 0
    };
  },
  computed: {
    newList() {
      let newList = [];
      for (let i = 0; i < this.informationList.length; i += 5) {
        let segment = this.informationList.slice(i, i + 5);
        newList.push(segment);
      }
      return newList;
    },
    activeIndex() {
      return this.currentIndex;
    }
  },
  methods: {
    handlePrePage() {
      if (this.currentIndex == 0) {
        return;
      }
      this.currentIndex -= 1;
    },
    handleNextPage() {
      if (this.currentIndex == this.newList.length - 1) {
        return;
      }
      this.currentIndex += 1;
    },
    handleSwitchPage(index) {
      this.currentIndex = index;
    }
  },
  mounted() {
    for (let i = 6; i < 100; i++) {
      this.informationList.push({
        id: i,
        date: "2020-05-26",
        title: `${i}留守老人如何养老？`,
        abstract:
          "这些企业家都有一个共同的身份，就是长江商学院EMBA26期5班的学子。施甸距离上海，有近三千公里。上海更是受新增疫情影响，出行有诸多不便。大家在远赴施甸之前，做了核酸检测，确保万无一失。",
        img: require("@/assets/images/home-new5.jpg")
      });
    }
  }
};
</script>

<style lang="scss" scoped>
#information-list-wrapper {
  width: 1240px;
  margin: 0 auto;
}
#information-list {
  ul {
    margin: 0;
    padding: 0;
    li {
      transition: all ease 0.1s;
      cursor: pointer;
      width: 100%;
      background-color: #f5f5f5;
      padding: 40px 60px;
      margin-bottom: 70px;
      display: flex;
      justify-content: space-between;
      position: relative;
      a {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
      }
      .left {
        // width: 386px;
        flex: 1 0 664px;
        padding-right: 70px;
        .information-date {
          font-size: 14px;
          color: #999;
        }
        .information-title {
          font-size: 24px;
          margin: 30px 0;
        }
        .information-abstract {
          line-height: 30px;
        }
      }
      .right {
        flex: 0 0 386px;
        height: 260px;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    li:hover {
      background-color: #004898;
      color: #fff;
      .information-date {
        color: #fff;
      }
    }
  }
}
</style>
